body {
  margin: 0;
  padding: 0;
  font-family: roboto;
}

h2.datatable-title {
  width: 50%;
  text-align: left;
  margin-left: 15px;
  color: #37474f;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
  width: 100%;
}

.datatable-header {
  display: flex;
  flex-direction: row;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.dropdown {
  margin: 10px 10px 10px 10px;
}

.datatable {
  background-color: white;
  text-align: center;
}

.highlight-bb {
  background-image: linear-gradient(-100deg, rgba(255, 255, 255, 0), #99cccc 85%, rgba(255, 255, 255, 0));
}

.refresh-btn {
  margin-right: 10px;
}
.App {
  background-color: #eceff1;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  display: flex;
  background-color: #263238;
  color: #eceff1;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.App-title {
  margin-top: auto;
  margin-bottom: auto;
}

.App-intro {
  font-size: large;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1 {
  font-size: 1.7em;
}

.gif {
  display: flex;
  max-height: 200px;
}

.

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

