h2.datatable-title {
  width: 50%;
  text-align: left;
  margin-left: 15px;
  color: #37474f;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
  width: 100%;
}

.datatable-header {
  display: flex;
  flex-direction: row;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.dropdown {
  margin: 10px 10px 10px 10px;
}

.datatable {
  background-color: white;
  text-align: center;
}

.highlight-bb {
  background-image: linear-gradient(-100deg, rgba(255, 255, 255, 0), #99cccc 85%, rgba(255, 255, 255, 0));
}

.refresh-btn {
  margin-right: 10px;
}