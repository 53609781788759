.App {
  background-color: #eceff1;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  display: flex;
  background-color: #263238;
  color: #eceff1;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.App-title {
  margin-top: auto;
  margin-bottom: auto;
}

.App-intro {
  font-size: large;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1 {
  font-size: 1.7em;
}

.gif {
  display: flex;
  max-height: 200px;
}

.

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
